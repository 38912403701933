<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <div class="filter-container">
      <Panel lineColor="var(--primary-color)">
        <v-row>
          <v-col
            class="filter-select-container"
            cols="12"
            xs="12"
            xm="6"
            md="6"
          >
            <FormAutocomplete
              label="Relatorios"
              v-model="currentReport"
              :options="reports"
            />
          </v-col>
        </v-row>

        <RelatoriosInput :inputs="inputs" :validation="$v.inputs" />

        <div class="filter-buttons">
          <FormButtonGroup align="end" spaced>
            <FormButton text="Imprimir" type="primario" @click="printReport" />
            <FormButton
              text="Gerar planilha"
              type="default"
              @click="generateCsv"
            />
            <FormButton text="Pesquisar" type="primario" @click="search" />
          </FormButtonGroup>
        </div>
      </Panel>
    </div>
    <Panel v-if="showGrid" lineColor="var(--primary-color)">
      <DataTable
        class="container-relatorio"
        :headers="tabledefs"
        :endpoint="endpoint"
        :additional-data="additionalData"
        :sortDesc="getSortedDesc() || false"
        ref="datatable"
        :sortBy="getSortedData() || 'name'"
        :search="false"
        @click="onClick"
      >
        <template
          v-for="(data, i) in outputs"
          v-slot:[data.data]="{ row, col }"
        >
          <div :key="i" v-if="data.formatter === 'aFormatterFunction_boolean'">
            <Chip v-if="col" color="green" text="Sim" :small="true" />
            <Chip v-else color="cyan" text="Não" :small="true" />
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_disponivel'"
          >
            <Chip v-if="col" color="green" text="Sim" :small="true" />
            <Chip v-else color="cyan" text="Não" :small="true" />
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_data'"
          >
            {{ getFormattedDateToUI(col) }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_cupom'"
          >
            {{ getFormattedCupom(col, row) }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_real'"
          >
            {{ formatReal(col) }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_canal_venda'"
          >
            {{
              col === 'APP'
                ? 'aplicativo'
                : col === 'MANUAL'
                ? 'venda+'
                : 'site'
            }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_aplicacao'"
          >
            {{ col === 'whitelabel' ? 'site próprio' : 'marketplace' }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_text_kebab'"
          >
            {{ formatTextToReadable(col) }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_status_pedido'"
          >
            {{ col ? orderStatusLabel[col] : '' }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_tipo_produto'"
          >
            {{ col === 'SIMPLES' ? 'Simples' : 'Conjunto' }}
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_produto_url'"
          >
            <a :href="getProductUrl(row)" target="_blank">
              Acesse seu produto</a
            >
          </div>
          <div :key="i" v-else-if="data.formatter === 'aFormatterFunction_url'">
            <a v-if="col" :href="col" target="_blank">Acesse o link</a>
          </div>
          <div
            :key="i"
            v-else-if="data.formatter === 'aFormatterFunction_caracteristicas'"
          >
            <div v-for="(c, i) in col" :key="i">
              <span>{{ c.nome }}: </span>
              <span style="color:gray">{{ getCharacteristicValue(c) }}</span>
            </div>
          </div>
          <div
            :key="i"
            v-else-if="
              data.formatter === 'aFormatterFunction_label_acao_pagamento'
            "
          >
            {{ col ? label_acao_pagamento[col] || col : '' }}
          </div>
          <div :key="i" v-else>{{ col }}</div>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import { RELATORIO_LOAD, RELATORIO_LOAD_CSV } from '@/store/actions/relatorios'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormButton from '@/components/form/FormButton'
import FormAutocomplete from '@/components/form/FormAutocomplete'
import { requiredIf } from 'vuelidate/lib/validators'
import RelatoriosInput from './RelatoriosInput'
import DataTable from '@/components/DataTable'
import OrderStatus from '../../../utils/orderStatus'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import * as _ from 'lodash'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'RelatoriosTable',
  components: {
    RelatoriosInput,
    FormButtonGroup,
    DataTable,
    FormButton,
    Panel,
    Chip,
    FormAutocomplete,
    MarketingModules
  },
  data: () => ({
    menu: {},
    headers: null,
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    TIPO_CUPOM_VALUE: {
      '%': '%',
      R$: '$',
      $: 'R$'
    },
    currentReport: null,
    reports: [],
    showGrid: false,
    tabledefs: null,
    additionalData: null,
    booleanDropdown: [
      { text: 'Nenhum', value: 'Nenhum' },
      { text: 'Sim', value: 'Sim' },
      { text: 'Não', value: 'Não' }
    ],
    dropdownTypes: ['tipoentrega', 'statuspedido'],
    dropdown: {
      statuspedido: OrderStatus.OrderStatusFilter,
      tipoentrega: OrderStatus.OrderDeliveryType
    },
    orderStatusLabel: OrderStatus.OrderStatusLabel,
    numberMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 0,
      masked: false
    },
    label_acao_pagamento: {
      TRANSACTION: 'Transação',
      CANCEL: 'Cancelamento/Estorno',
      CAPTURE: 'Captura'
    },
    modulosForm: [ModulesEnum.TODOS]
  }),
  validations: {
    inputs: {
      $each: {
        value: {
          required: requiredIf(function(model) {
            return model.obrigatorio && !model.value
          })
        }
      }
    }
  },
  created() {
    this.getReports()
  },
  computed: {
    ...mapGetters([
      'getEmpresaId',
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    endpoint() {
      return `/api/v4/report/datatable/${this.getFornecedorId}`
    },
    inputs() {
      return (_.get(this.currentReport, 'inputs') || []).filter(i => !i.getter)
    },
    outputs() {
      return (_.get(this.currentReport, 'outputs') || []).filter(i => !i.getter)
    },
    showBotaoRecomendacao() {
      return this.getFornecedorTag['uses-share-coupom']
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  watch: {
    currentReport() {
      this.tabledefs = this.getTableDefs()
      this.showGrid = false
    }
  },
  methods: {
    onClick() {},
    getReports() {
      this.$store.dispatch(RELATORIO_LOAD).then(result => {
        const _result = result.filter(r => {
          if (!r.tag) return true
          if (r.tag) {
            if (this.getFornecedorTag[r.tag]) {
              return true
            } else if (r.tag.startsWith('not-')) {
              return !this.getFornecedorTag[r.tag.slice(4, r.tag.length)]
            }
          }
          return false
        })
        this.reports = _result.map(r => {
          let inputs = r.inputs.map(i => {
            return { ...i, value: null, error: '' }
          })
          const _r = { ...r, inputs }
          return { ..._r, text: r.nome, value: _r }
        })
      })
    },
    getTableDefs() {
      if (!this.currentReport) return []
      const defs = this.currentReport.outputs
        .filter(o => !!o.caption)
        .map(o => {
          const isBoolean = o.tipo === 'boolean' ? true : false
          let customClass = o.class || isBoolean ? 'center' : ''
          return {
            text: o.caption,
            align: customClass ? 'center' : '',
            value: o.data
          }
        })
      return defs
    },
    getGetterInfo(info) {
      switch (info) {
        case 'getIdFornecedor':
          return this.getFornecedorId
        case 'getIdEmpresa':
          return this.getEmpresaId
        default:
          return ''
      }
    },
    printReport() {
      window.print()
    },
    getBodyToCsv() {
      const currentReport = { ...this.currentReport }
      this.$v.inputs.$touch()
      if (this.$v.inputs.$invalid) return
      const data = this.prepareData(currentReport)
      const additionalData = {
        idrelatorio: data.idrelatorio,
        inputs: data.inputs
      }
      return {
        additionalData: additionalData
      }
    },
    generateCsv() {
      const body = this.getBodyToCsv()
      if (!body) return
      this.$vueOnToast.pop(
        'warning',
        'Gerando planilha, isso pode levar alguns segundos'
      )
      this.$store.dispatch(RELATORIO_LOAD_CSV, body).then(result => {
        this.reports = result.map(r => {
          let inputs = r.inputs.map(i => {
            return { ...i, value: null, error: '' }
          })
          const _r = { ...r, inputs }
          return { ..._r, text: r.nome, value: _r }
        })
      })
    },
    search() {
      if (this.showGrid) {
        this.showGrid = false
        this.tabledefs = this.getTableDefs()
        this.headers = this.tabledefs
        setTimeout(() => this.doSearch(), 400)
      } else {
        this.doSearch()
      }
    },
    doSearch() {
      const currentReport = { ...this.currentReport }
      this.$v.inputs.$touch()
      if (this.$v.inputs.$invalid) return
      const data = this.prepareData(currentReport)
      this.additionalData = data
      this.showGrid = true
    },
    prepareData(data) {
      data.inputs = data.inputs.map(i => {
        let value = i.value
        if (i.getter) {
          value = this.getGetterInfo(i.getter)
        }
        if (i.tipo === 'boolean') {
          switch (i.value) {
            case 'Sim':
              value = true
              break
            case 'Não':
              value = false
              break
            default:
              value = ''
          }
        }
        if (i.tipo === 'date' && i.value) {
          value = this.getFormattedDate(i.value, false, false)
        }
        return this.formatData(i, value)
      })
      return this.formatData(data)
    },
    formatData(data, value = '') {
      return {
        idrelatorio: data.idrelatorio,
        inputs: data.inputs,
        outputs: data.outputs,
        nome: data.nome,
        value
      }
    },
    getFormattedDateToUI(date) {
      return date && moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    getFormattedCupom(data, item) {
      return item && item.tipo === '$'
        ? this.formatReal(data)
        : data + item.tipo
    },
    // mover
    getFormattedDate(date, isDefaultTZ, hasTimezone) {
      const separatorTime = date.includes('T') ? 'T' : ' '
      const arrayDatetime = date ? date.split(separatorTime) : null
      const separatorDate = date.includes('/') ? '/' : '-'
      const arrayDate =
        arrayDatetime && arrayDatetime[0]
          ? arrayDatetime[0].split(separatorDate)
          : null
      let dateString = ''
      if (arrayDate) {
        if (date.includes('/')) {
          dateString = arrayDatetime[1]
            ? `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}T${arrayDatetime[1]}`
            : `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`
        } else {
          let time = arrayDatetime[1] ? arrayDatetime[1].split('-') : []
          dateString = time[0]
            ? `${arrayDatetime[0]}T${time[0]}`
            : arrayDatetime[0]
        }
      }
      let formatedDate =
        dateString && isDefaultTZ ? dateString + '-03:00' : dateString
      return hasTimezone
        ? moment(formatedDate, moment.ISO_8601).format()
        : formatedDate
    },
    renderValorDesconto(data) {
      return data.tipodesconto === '%'
        ? `${data.valordesconto}${data.tipodesconto}`
        : `${this.TIPO_CUPOM_VALUE[data.tipodesconto]}${this.formatReal(
            data.valordesconto
          )}`
    },
    renderData(col) {
      return col ? moment(col).format('DD/MM/YYYY') : ''
    },
    buildComplexProductId(idproduto, idcadastroextra = '', idprodutoerp = '') {
      idproduto = idproduto ? idproduto : ''
      idcadastroextra = idcadastroextra ? idcadastroextra : ''
      idprodutoerp =
        idprodutoerp && idprodutoerp !== 'undefined' && idprodutoerp !== 'null'
          ? idprodutoerp
          : ''
      return `${idproduto}+${idcadastroextra}+${idprodutoerp}`.replace(
        /[\+]{1,2}$/g,
        ''
      )
    },
    getProductUrl(item) {
      if (!item) return ''
      const domain = `http://${item.dominio}/${item.fornecedor_slug}`
      return `${domain}/produto/${item.slug}`
    },
    getCharacteristicValue(item) {
      if (!item) return ''
      const isBoolean = item.tipo === 'boolean' ? true : false
      return isBoolean
        ? item.descricao === 'true'
          ? 'Sim'
          : 'Não'
        : item.descricao
    },
    formatReal(v = '0') {
      return `${parseFloat(v).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`
    },
    formatTextToReadable(text, separator = '_') {
      const _str = text.toLowerCase().split(separator)
      let result = _str.length ? this.capitalize(_str[0]) : ''
      if (_str.length > 1) {
        _str.slice(1).forEach(s => {
          result += ` ${s}`
        })
      }
      return result
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    getSortedData() {
      const sortedObject = this.outputs.find(obj => obj.sortBy === true)
      return sortedObject?.data
    },
    getSortedDesc() {
      const sortedObject = this.outputs.find(obj => obj.sortDesc === true)
      return sortedObject?.sortDesc
    }
  }
}
</script>

<style lang="scss">
@media print {
  .menu-top,
  .front-header,
  .filter-buttons,
  .custom-footer,
  .data-table .data-table-filter,
  i.v-icon.notranslate.far.fa-question-circle.theme--light {
    display: none !important;
  }

  .v-input.v-input--dense.theme--light.v-text-field.v-text-field--solo-flat.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-text-field--rounded.v-select {
    max-height: 60px;
  }

  .panel {
    background: red;
    div {
      background-color: red;
    }
  }
  td.clickable {
    font-size: 10px;
  }
  .v-data-table.elevation-1.theme--light {
    box-shadow: unset !important;
    border: none !important;
  }
  .mx-auto.card-default.v-card.v-sheet.theme--light {
    border-color: white !important;
  }
}
</style>
