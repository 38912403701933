<template>
  <div class="page">
    <v-row>
      <v-col cols="12" xs="12" xm="6" md="6">
        <div v-for="(data, i) in inputs" :key="i">
          <div v-if="!data.getter">
            <div v-if="data.tipo === 'text'">
              <FormField
                v-model="data.value"
                :label="data.caption"
                :help="data.hint"
                :validation="getValidationField(data.data, 'value')"
                :mask="data.mask || ''"
              />
            </div>
            <div v-if="data.tipo === 'number'">
              <FormField
                v-model="data.value"
                :label="data.caption"
                :validation="getValidationField(data.data, 'value')"
                :money="numberMask"
                :help="data.hint"
              />
            </div>
            <div v-if="data.tipo === 'date'">
              <FormDatePicker
                v-model="data.value"
                :label="data.caption"
                :hint="data.hint"
                :validation="getValidationField(data.data, 'value')"
              />
            </div>

            <div v-if="data.tipo === 'boolean'">
              <FormSelect
                v-model="data.value"
                :label="data.caption"
                :options="booleanDropdown"
                :hint="data.hint"
                :validation="getValidationField(data.data, 'value')"
              />
              <!-- :validation="$v.inputs" -->
            </div>
            <div v-if="dropdownTypes.includes(data.tipo)">
              <FormSelect
                v-model="data.value"
                :label="data.caption"
                :options="dropdown[data.tipo]"
                :help="data.hint"
                :multiple="dropdownIsMultiple.includes(data.tipo)"
                :validation="getValidationField(data.data, 'value')"
              />
            </div>
            <span
              class="help-block"
              v-if="data.obrigatorio && !data.value && hasError"
            >
              Este campo é obrigatório.
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormDatePicker from '@/components/form/FormDatePicker'
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import moment from 'moment'

// TODO mover
const OrderStatusFilter = [
  { text: 'Todos', value: 'TODOS' },
  { text: 'Orçamentos novos', value: 'ORCAMENTO_NOVO' },
  { text: 'Orçamentos aceitos', value: 'ORCAMENTO_PRECIFICADO' },
  { text: 'Novos pedidos', value: 'ENVIADO' },
  { text: 'Em separação', value: 'SEPARANDO' },
  { text: 'Pronto para entrega', value: 'PAGO' },
  { text: 'Em entrega', value: 'EMENTREGA' },
  { text: 'Entregues', value: 'ENTREGUE' },
  { text: 'Cancelados', value: 'CANCELADO' },
  { text: 'Pronto para retirada', value: 'PRONTO_RETIRADA' },
  { text: 'Aguardando Pagamento', value: 'AGUARDANDOPAGAMENTO' }
]

const OrderDeliveryType = [
  { text: 'Todos', value: 'TODOS' },
  { text: 'Delivery', value: 'DELIVERY' },
  { text: 'Retirada', value: 'RETIRADA' }
]
const AcaoPagamentoType = [
  { text: 'Todos', value: 'TODOS' },
  { text: 'Transação', value: 'TRANSACTION' },
  { text: 'Captura', value: 'CAPTURE' },
  { text: 'Estorno', value: 'CANCEL' }
]

export default {
  name: 'RelatoriosTable',
  components: {
    FormField,
    FormSelect,
    FormDatePicker
  },
  props: {
    inputs: {
      type: Array,
      default: () => null,
      required: true
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    hasError: false,
    booleanDropdown: [
      { text: 'Nenhum', value: 'Nenhum' },
      { text: 'Sim', value: 'Sim' },
      { text: 'Não', value: 'Não' }
    ],
    dropdownTypes: ['tipoentrega', 'statuspedido', 'acaopagamento'],
    dropdownIsMultiple: ['statuspedido'],
    dropdown: {
      statuspedido: OrderStatusFilter,
      tipoentrega: OrderDeliveryType,
      acaopagamento: AcaoPagamentoType
    },
    numberMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 0,
      masked: false
    }
  }),
  computed: {
    ...mapGetters(['getEmpresaId', 'getFornecedorId', 'getFornecedorTag'])
  },
  watch: {
    inputs(val) {
      val.forEach(v => {
        if (v.default) {
          v.value = this.getDefaultValue(v.default)
        }
      })
    }
  },
  methods: {
    getValidationField(id, columns) {
      if (this.validation && this.validation.$each) {
        for (const i in this.validation.$each.$iter) {
          let val = this.validation.$each.$iter[i]
          if (_.get(val, '$model.data', '') === id) {
            return _.get(val, columns, null)
          }
        }
      }
      return null
    },
    getDefaultValue(valueDefault) {
      switch (valueDefault) {
        case 'today':
          return moment().format('YYYY-MM-DD')
        case 'lastmonth':
          return moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD')
        default:
          return valueDefault || ''
      }
    }
  }
}
</script>
